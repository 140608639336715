@import "/src/styles/styles.scss";
.authPopup {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  .authPopup-title {
    transition: $animationTransition;
    text-align: center;
    color: $primaryColor;
    max-width: 300px;
    margin-top: 0;
    @include max-xs() {
      margin-bottom: 5px;
    }
  }
  figure {
      width: 100px;
      margin: 5px auto;
  }
  .viewButtons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 10px 0;
    @include max-xs() {
      max-width: 305px;
    }
  }
  .btn-to-register {
    margin-top: 16px;
    max-width: 300px;
    width: 100%;
  }
  &.authPopup-register {
    .authPopup-title {
      color: $secondaryColorD;
    }
  }
  .providers {
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 1;
    p {
      color: $primaryColor;
      margin: 5px;
    }
    .providersLogin .btn{
      min-width: 133px;
    }
  }
}
