@import "/src/styles/styles.scss";
$animationTime: 600ms;
.switchTransition {
  display: grid;
  grid-template-areas: "item";
  overflow: hidden;
  .item {
    grid-area: item;
  }
  &.fadeTransition {
    .item {
      transition: opacity #{$animationTime} ease;
    }
    .item-exit {
      opacity: 1;
    }
    .item-exit-active {
      opacity: 0;
    }
    .item-exit-done {
      opacity: 0;
    }
    .item-enter {
      opacity: 0;
    }
    .item-enter-active {
      opacity: 1;
    }
    .item-enter-done {
      opacity: 1;
    }
  }
  &.leftToRight {
    .item {
      transition: transform #{$animationTime} ease;
      transform: translateX(-100%);
    }
    .item-exit {
      transform: translateX(0%);
    }
    .item-exit-active {
      transform: translateX(100%);
      // background-color: red;
    }
    .item-exit-done {
      transform: translateX(100%);
    }
    .item-enter {
      transform: translateX(-100%);
    }
    .item-enter-active {
      transform: translateX(0%);
    }
    .item-enter-done {
      transform: translateX(0%);
    }
  }
  &.rightToLeft {
    .item {
      transition: transform #{$animationTime} ease;
      transform: translateX(100%);
    }
    .item-exit {
      transform: translateX(0%);
    }
    .item-exit-active {
      transform: translateX(-100%);
      // background-color: red;
    }
    .item-exit-done {
      transform: translateX(-100%);
    }
    .item-enter {
      transform: translateX(-100%);
    }
    .item-enter-active {
      transform: translateX(0%);
    }
    .item-enter-done {
      transform: translateX(0%);
    }
  }
}
