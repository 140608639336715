@import "/src/styles/styles.scss";
.quiz-editor {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: stretch;
  padding: 10px;
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  @include into-lg() {
    margin: auto;
    max-width: $screen-lg;
  }
  @include into-elg() {
    margin: auto;
    max-width: $screen-elg;
  }
  @include min-lg() {
    .question-editor-main {
      .input input,
      .input {
        font-size: 24px;
        .errorMessage {
          min-height: 24.4px;
          font-size: 16px;
        }
      }
      .input-label {
        padding: 23px 0 0;
        label span {
          top: 33px;
        }
        &:hover label span,
        &.filled label span,
        .field:focus + label span,
        .field:hover + label span {
          font-size: 16px;
          top: 0;
        }
      }
    }
  }
}
