@import "/src/styles/styles.scss";
.errorMessage {
  min-height: 17.5px;
  font-size: 10px;
  color: $dangerColor;
  display: block;
  padding: 3px 0;
  &.errorMessage-lg{
    font-size: 12px;
    min-height: 20px;
  }
}