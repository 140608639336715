@import "/src/styles/styles.scss";
@import "/src/components//BasicComponents/Button/Button.scss";
.btnLoading {
  position: relative;
  min-width: 190px;
  span {
    transition: opacity 300ms;
    opacity: 1;
  }
  svg {
    max-width: 65px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 300ms;
    opacity: 0;
    [id^="circle"] {
      fill: $white;
      transition: 300ms;
    }
    #bigCircle {
      opacity: 0;
    }
    #checkmark {
      transition: 300ms;
      transition-delay: 300ms;
      stroke-dashoffset: 50;
    }
    #x1,
    #x2 {
      transition: 300ms;
      transition-delay: 300ms;
      stroke-dashoffset: 42;
    }
    @include min-sm () {
      max-width: 100px;
    }
  }
  &.btn-light-border {
    svg {     
      [id^="circle"] {
        fill: $primaryColor;
      }
      path#checkmark,path#x1,path#x2 {
        stroke: $white;
      }
    }
    &:hover,&:active,&:focus-within {
      [id^="circle"] {
        fill: $white;
      }
      #checkmark,#x1,#x2 {
        stroke: $primaryColor;
      }
    }
  }
  &.loading {
    cursor:wait;
    span {
      opacity: 0;
    }
    svg {
      opacity: 1;
      [id^="circle"] {
        opacity: 0;
        animation-name: fadeinout;
        animation-duration: 2s;
        animation-iteration-count: infinite;
      }
      #circle2 {
        animation-delay: 0.25s;
      }
      #circle3 {
        animation-delay: 0.5s;
      }
      #circle4 {
        animation-delay: 0.75s;
      }
    }
  }
  &.success {
    span {
      opacity: 0;
    }
    svg {
      opacity: 1;
      [id^="circle"] {
        opacity: 1;
        r: 28.114206;
        cx: 71.965042;
        cy: 28.114206;
      }
      #checkmark {
        opacity: 1;
        stroke: $buttonColor;
        stroke-dashoffset: 0;
      }
    }
  }

  &.fail.btn {
    background-color: $dangerColor;
    span {
      opacity: 0;
    }
    svg {
      opacity: 1;
      [id^="circle"] {
        fill: $white;
        opacity: 1;
        r: 28.114206;
        cx: 71.965042;
        cy: 28.114206;
      }
      #x1,
      #x2 {
        opacity: 1;
        stroke: $dangerColor;
        stroke-dashoffset: 0;
      }
    }
  }
}

@keyframes fadeinout {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
