@import "/src/styles/styles.scss";

.link {
  text-decoration: none;
  position: relative;
  display: inline;
  i {
    margin: 0;
    vertical-align: middle;
    display: inline-block;
  }
  span {
    color: $primaryColor;
  }
  &::after {
    content: "";
    position: absolute;
    bottom: -4px;
    left: 0;
    height: 2px;
    width: 0;
    background-color: $primaryColor;
    transition: width 200ms ease-in-out;
  }
  &:hover {
    &::after {
      width: 100%;
    }
  }
  @include min-sm() {
    margin: 5px;
  }
  &.toogleLink {
    span {
      display: none;
      @include min-sm() {
        display: inline;
      }
    }
    i {
      @include min-sm() {
        display: none;
      }
    }
    @include max-xs() {
      &::after {
        display: none;
      }  
    }
  }
  &.light {
    span {
      color: $primaryColor;
    }
    &::after {
      background-color: $primaryColor;
    }
  }
}
