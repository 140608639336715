@import "/src/styles/styles.scss";
.quizFinishedComponent {
  text-align: center;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  h2 {
    color: $primaryColor;
    font-size: 16px;
  }
  .saveProgress {
    margin: 16px 5px;
  }
  @include min-sm() {
    h2 {
      color: $primaryColor;
      font-size: 24px;
    } 
  }
}