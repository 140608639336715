@import "/src/styles/styles.scss";
.fadeup {
  transition: transform 400ms ease-in-out, opacity 400ms ease-in-out;
  background-color: $white;
  background-image: repeating-linear-gradient(transparent 10%, $white 30%, $white);
}
.fadeup-enter {
  opacity: 0;
}
.fadeup-enter-active {
  transform: translateY(0%);
  opacity: 1;
}
.fadeup-enter-done {
  transform: translateY(0%);
  opacity: 1;
}
.fadeup-exit {
  
}
.fadeup-exit-active {
  transform: translateY(100%);
  opacity: 0;
}

.fadeup-exit-done {
  transform: translateY(100%);
  opacity: 0;
}

.cover {
  width: 100%;
  min-height: 100vh;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
}
