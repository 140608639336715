@import "/src/styles/styles.scss";
.pageTransitionContainer {
  display: grid;
  grid-template-areas: "page";
  overflow: hidden;
}
.page {
  grid-area: page;
  .page-appear,
  .page-appear-done,
  .page-appearing-done {
    transition: none;
  }
}

.fadeTransition {
  .page {
    transition: opacity 300ms ease-in-out;
    opacity: 0;
  }
  .page-appear,
  .page-appear-done,
  .page-appearing-done {
    opacity: 1;
  }
  .page-exit {
    opacity: 1;
  }
  .page-exit-active {
    opacity: 0;
  }
  .page-exit-done {
    opacity: 0;
  }
  .page-enter {
    opacity: 0;
  }
  .page-enter-active {
    opacity: 1;
  }
  .page-enter-done {
    opacity: 1;
  }
}
.leftToRight {
  .page {
    transition: transform 300ms ease-in-out;
    transform: translateX(-100%);
  }
  .page-appear,
  .page-appear-done,
  .page-appearing-done {
    transform: translateX(0%);
  }
  .page-exit {
    transform: translateX(0%);
  }
  .page-exit-active {
    transform: translateX(100%);
    // background-color: red;
  }
  .page-exit-done {
    transform: translateX(100%);
  }
  .page-enter {
    transform: translateX(-100%);
  }
  .page-enter-active {
    transform: translateX(0%);
  }
  .page-enter-done {
    transform: translateX(0%);
  }
}

.rightToLeft {
  .page {
    transition: transform 300ms ease-in-out;
    transform: translateX(100%);
  }
  .page-appear,
  .page-appear-done,
  .page-appearing-done {
    transform: translateX(0%);
  }
  .page-exit {
    transform: translateX(0%);
  }
  .page-exit-active {
    transform: translateX(-100%);
    // background-color: red;
  }
  .page-exit-done {
    transform: translateX(-100%);
  }
  .page-enter {
    transform: translateX(-100%);
  }
  .page-enter-active {
    transform: translateX(0%);
  }
  .page-enter-done {
    transform: translateX(0%);
  }
}
