@import "/src/styles/styles.scss";

.eye {
  perspective: 1000;
  path {
    transition: $animationTransition;
  }
  #eye-ball, #eye-upper, #eye-lower {
    fill: $primaryColor;
  }
  #eye-ball {
    opacity: 1;
    fill: $primaryColor;
  }
  #eye-upper {
    transform-origin: 0px 55.5px 0;
    fill: $primaryColor;
  }
  &.eye-closed {
    #eye-upper {
      transform: rotateX(180deg);
      -webkit-transition: 0.6s;
      -webkit-transform-style: preserve-3d;

      -moz-transition: 0.6s;
      -moz-transform-style: preserve-3d;

      -o-transition: 0.6s;
      -o-transform-style: preserve-3d;

      transition: 0.6s;
      transform-style: preserve-3d;

      position: relative;
    }
    #eye-ball {
      opacity: 0;
    }
  }
}
