@import "/src/styles/styles.scss";
$transition: 400ms;
.menuIcon {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: transform #{$transition};
  .line {
    fill: none;
    transition: stroke-dasharray 400ms, stroke-dashoffset 400ms, stroke 400ms;
    stroke: $primaryColor;
    stroke-width: 5.5;
    stroke-linecap: round;
    &.top {
      stroke-dasharray: 40 139;
    }
    &.bottom {
      stroke-dasharray: 40 180;
    }
  }
  &.active {
    transform: rotate(45deg);
    .top {
      stroke-dashoffset: -98px;
    }
    .bottom {
      stroke-dashoffset: -138px;
    }
  }
  &:hover,
  &:active {
    .line {
      stroke: $primaryColorD;
    }
  }
}
