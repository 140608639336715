@import "/src/styles/styles.scss";
.menuButton {
  width: 60px;
  height: 60px;
}
.headerMenuMobile {
  padding: 0;
  background-color: $transparentDarkBg;
  flex-basis: 100%;
  margin: 0;
  list-style: none;
  box-shadow: inset 0px 1px 5px $shadowColor;
  transition: height 0.4s ease-in;
  overflow: hidden;

  .headerMenuMobile-item {
    &:first-of-type {
      margin-top: 5px;
    }
    &:last-of-type {
      margin-bottom: 5px;
    }
    padding: 16px;
    text-align: center;
    .link {
      span {
        color: $white;
        font-size: 18px;
      }
      &::after {
        background-color: $white;
      }
    }
  }
}
