@import "/src/styles/styles.scss";
$transition: .5s;
.initial-loading {
  background: rgba(255,255,255,1);
  position: absolute;
  width: 100%;
  min-height: 100vh;
  display: flex;
  z-index: 2;
  justify-content: center;
  align-items: center;
  transition: background #{$transition} ease-in;
  &.initial-loading-exiting {
    background: rgba(255,255,255,0);
  }
  .logo-animation {
    opacity: 1;
    transition: all #{$transition} ease-in;
    transform-origin: top left;
  }
}
//parent
#root{
  overflow: clip;
}