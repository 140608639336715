.fade {
  transition: opacity 300ms;
  opacity: 0;
}
.fade-enter {
  // opacity: 1;
}
.fade-enter-active, .fade-enter-done {
  opacity: 1;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active, .fade-exit-done {
  opacity: 0;
}