//screen variables
$screen-xxs: 375px !default;
$screen-xs: 576px !default;
$screen-sm: 768px !default;
$screen-md: 992px !default;
$screen-lg: 1200px !default;
$screen-elg: 1400px !default;
$screen-xxs-max: ($screen-xs - 1) !default;
$screen-xs-max: ($screen-sm - 1) !default;
$screen-sm-max: ($screen-md - 1) !default;
$screen-md-max: ($screen-lg - 1) !default;
$screen-lg-max: ($screen-elg - 1) !default;

$shadow: 2px 2px 15px 2px $shadowColor;

$animationTransition: 0.4s;