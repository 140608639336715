.option-editor {
  display: flex;
  align-items: flex-start;
  .focusInput {
    margin: 0 0 5px 5px;
    flex-grow: 1;
  } 
  .checkbox {
    margin-top: 8px;
  }
  .icon-btn {
    margin-right: 0;
    margin-left: 5px;
    margin-top: 12px;
    margin-bottom: 5px;
    flex-shrink: 0;
  }
}