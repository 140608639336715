//colors
$white: white;
$lighGray: #f3f2ef;
$gray: #7B8FA1;

$bgColor:$white;

$primaryColorL: #508FD0;
$primaryColor: #2C74B3; 
$primaryColorD: #205295;
$primaryColorDD: #0A2647;


$secondaryColorL: #B3E8E5;
$secondaryColor: #82DBD8; 
$secondaryColorD: #3BACB6;
$secondaryColorDD: #2F8F9D;

$dangerColor: #EB455F;
$dangerColorD: #D61C4E;

$grayFontColor: #476072;

$transparentLightBg: rgb(44, 116, 179, 0.1);
$transparentDarkBg: rgb(44, 116, 179, 0.9);
$transparentRedBg: rgb(235, 69, 95, 0.9);

$buttonColor: $primaryColor;
$buttonColorHover: $primaryColorD;
$buttonColorPressed: $primaryColorDD;

$secondaryButtonColor: $secondaryColorD;
$secondaryButtonColorHover: $secondaryColorDD;
$secondaryButtonColorPressed: $secondaryColorDD;


//shadow
$shadowColor: #888888;