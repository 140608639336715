@import "/src/styles/styles.scss";
.poweredBy {
  text-decoration: none;
  color: $gray;
  font-size: 10px;
  padding: 10px;
  transition: 300ms;
  &:hover,
  &:active,
  &:focus {
    background: $gray;
    color: $white;
    border-radius: 10px;
  }
  @include min-sm() {
    font-size: 12px;
  }
}
