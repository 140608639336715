* {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  box-sizing: border-box;
}
button {
  background: none;
  border: none;
  cursor: pointer;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}