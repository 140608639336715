@import "/src/styles/styles.scss";
@import "/src/components/BasicComponents/Button/Button.scss";

$mdSize: 33px;
$mdSizeLabel: 24px;
$smSize: 23px;

.btn.icon-btn {
  border-radius: 50%;
  padding: 5px;
  margin: 10px;
  &:disabled {
    visibility: hidden;
  }
  svg {
    width: $mdSize;
    height: $mdSize;
    display: block;
    &.stroke {
      path {
        stroke: $white;
      }
    }
    &.fill {
      path {
        fill: $white;
      }
    }
  }
  &.label {
    border-radius: 50px;
    padding-right: 15px;
    svg {
      width: $mdSizeLabel;
      height: $mdSizeLabel;
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
    }
    span {
      vertical-align: middle;
    }
    @include min-sm() {
      padding: 13px 10px;
      font-size: 16px;
    }
  }
  &.btn-light {
    svg {
      &.stroke {
        path {
          stroke: $primaryColor;
        }
      }
      &.fill {
        path {
          fill: $primaryColor;
        }
      }
    }
  }
  &:focus-within,
  &:active {
    &.btn-light {
      svg {
        &.stroke {
          path {
            stroke: $white;
          }
        }
        &.fill {
          path {
            fill: $white;
          }
        }
      }
    }
  }
  @include intoMouseScreen() {
    &:hover {
      &.btn-light {
        svg {
          &.stroke {
            path {
              stroke: $white;
            }
          }
          &.fill {
            path {
              fill: $white;
            }
          }
        }
      }
    }
  }
  &.btn-sm {
    width: $smSize;
    height: $smSize;
    padding: 5px;
    margin: 10px;
  }
}
$iconAnimationTime: 300ms;
.loginIcon {
  #loginIcon-arrow {
    transition: #{$iconAnimationTime} transform ease-in-out;
  }
  @include intoMouseScreen() {
    &:hover {
      #loginIcon-arrow {
        transform: translate(5px);
      }
    }
  }
}
