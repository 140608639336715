$animation-time: 0.3s;
.ListAnimation{
  .item {
    transition: height $animation-time ease-in;
    &.item-enter {
      height: 0;
      animation: $animation-time animate-pop cubic-bezier(0.26, 0.53, 0.74, 1.48);
    }
    &.item-exit {
      animation: $animation-time animate-pop cubic-bezier(0.26, 0.53, 0.74, 1.48);
      animation-direction: reverse;
      animation-fill-mode: forwards;
    }
  }
}

@keyframes animate-pop {
  0% {
    opacity: 0;
    transform: scale(0.7, 0.7);
  }

  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}
