@import "/src/styles/styles.scss";
.loginForm {
  background: $white;
  form {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    width: 100%;
    margin: auto;
  }
  .input {
    @include min-xss() {
      min-width: 305px;
    }
    
  }

  .btn {
    margin-top: 10px;
    align-self: center;
    width: 100%;
    max-width: 300px;
  }
}
