@import "/src/styles/styles.scss";

.takeQuiz {
  display: grid;
  grid: 1fr min-content/1fr;
  min-height: 100vh;
  .poweredWrapper {
    display: flex;
    justify-content: center;
    @include min-sm() {
      justify-content: flex-end;
    }
  }
}
