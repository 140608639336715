@import "/src/styles/styles.scss";
.question-editor {
  color: $primaryColor;
  padding: 0 0 20px;
  .question-editor-main {
    display: flex;
    .icon-btn {
      flex-shrink: 0;
      margin:15px 0 0 4px;
      align-self: flex-start;
      @include min-lg() {
        margin:30px 0 0 4px;
      }
    }
  }
  .input {
    width: 100%;
    align-self: flex-start;
  }
  .checkbox {
    vertical-align: middle;
  }
  .question-editor-options {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .btn.icon-btn.more-icon {
    margin: 0 0 0 4px;
  }
}
