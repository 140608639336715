@import "/src/styles/styles.scss";

.question-type-picker {
  // position: sticky;
  // bottom: 0;
  width: 100%;
  background-color: $white;
  h3 {
    text-align: center;
    color: $primaryColor;
  }
  .buttons {
    display: grid;
    grid-template-rows: 1fr;
    grid-row-gap: 5px;
    @include min-xss() {
      grid-template-columns: repeat(3, 1fr);
      max-width: 460px;
      margin: auto;
      .btn-save {
        width: 100%;
        max-width: 300px;
        justify-self: stretch;
        grid-column: auto/span 3;
        margin: auto;
      }
    }
    @include min-xss() {
      grid-template-columns: repeat(3, 1fr);
      .btn-save {
        width: 100%;
        max-width: 300px;
        justify-self: stretch;
        grid-column: auto/span 3;
        margin: auto;
      }
    }
    @include min-sm() {
      max-width: 540px;
    }
  }
  .btn {
    min-width: 110px;
  }
  @include min-sm() {
    .btn {
      min-width: 150px;
    }
  }
}
