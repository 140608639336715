@import "/src/styles/styles.scss";

.loading-screen {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  
  svg {
    width: 100px;
    opacity: 0;
    animation: fadein 2s forwards 1s;
    @include min-lg() {
      width: 200px;
    }
  }
}
@keyframes fadein {
  from { opacity: 0; }
  to { opacity: 1; }
}