@import "/src/styles/styles.scss";
.headerMenu {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  padding: 16px;
  align-items: center;
  .headerMenu-item {
    margin: 0 5px;
    .link span {
      font-size: 18px;
    }
  }
  @include max-xs() {
    padding: 0;
  }
}
