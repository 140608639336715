@import "/src/styles/styles.scss";
$animation-time: 0.3s ease-in;

.focusInput {
  width: 100%;
  .field {
    border: none;
    background: none;
    outline: none;
    padding: 5px;
  }
}
.input-label {
  display: inline-block;
  padding: 15px 0 0;
  position: relative;
  margin-bottom: 15px;
  overflow: hidden;
  word-break: break-word;
  vertical-align: middle;
  .field {
    color: $primaryColorD;
    width: 100%;
    background: $lighGray;
    border-radius: 10px;
    padding: 10px;
  }
  label {
    width: 0;
    height: 0;
    display: block;
    span {
      color: $primaryColor;
      position: absolute;
      top: 25px;
      left: 10px;
      transition: all #{$animation-time};
      pointer-events: none;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
      width: calc(100% - 20px);
    }
  }
  .border {
    position: absolute;
    height: 5px;
    width: 100%;
    bottom: 0;
    overflow: hidden;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    pointer-events: none;
    &:before {
      content: "";
      background-color: $primaryColor;
      width: 100%;
      height: 2px;
      position: absolute;
      bottom: 0;
      left: 0;
      transition: transform #{$animation-time};
    }
    &:after {
      content: "";
      background-color: $primaryColorD;
      width: 100%;
      height: 3px;
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      transition: transform #{$animation-time};
    }
  }
  &:hover label span,
  &.filled label span,
  .field:focus + label span,
  .field:hover + label span {
    font-size: 10px;
    color: $primaryColorD;
    top: 0;
    left: 0;
    width: 100%;
  }
  label:hover .border:after,
  &.filled .border:after,
  .field:focus ~ .border:after,
  .field:hover ~ .border:after {
    transform: translateX(0%);
  }
  &.input-lg {
    font-size: 20px;
    .field {
      font-size: 20px;
    }
    label:hover span,
    &.filled label span,
    .field:focus + label span,
    .field:hover + label span {
      font-size: 12px;
    }
    .border {
      &::before {
        height: 2px;
      }
      &:after {
        height: 3px;
      }
    }
  }
}
.input-layer {
  display: flex;
  flex-direction: column-reverse;
  .field {
    background: $white;
    position: relative;
    padding: 10px;
    border-radius: 10px;
    font-size: 16px;
    border: 1px solid $primaryColorL;
    margin-bottom: 10px;
  }
  label:after {
    transition: all #{$animation-time};
  }
  .field:focus,
  .field:hover {
    z-index: 2;
    & + label:after {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      background: $transparentLightBg;
      opacity: 1;
      transition: opacity 0.3s;
      pointer-events: none;
    }
  }
  label {
    margin-bottom: 10px;
    color: $primaryColor;
  }
  .border {
    display: none;
  }
  &.input-lg {
    font-size: 18px;
    .field {
      font-size: 18px;
    }
  }
}
.input-with-icon {
  .field {
    padding-right: 47px;
  }
  .input-icon {
    position: absolute;
    top: 16px;
    right: 0;
    width: 40px;
    height: 40px;
  }
}
