@import "/src/styles/styles.scss";
.startQuiz {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100%;
  .startBtn, .registerBtn {
    min-width: 300px;
  }
  p {
    color: $primaryColor;
  }
}