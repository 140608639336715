@import "/src/styles/styles.scss";
.notification-wrapper {
  position: sticky;
  height: 0px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}
.ul-notification {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  @include min-sm() {
    max-width: 400px;
  }
  .item {
    padding: 5px;
  }
}

.notification {
  padding: 16px;
  z-index: 2;
  width: 100%;
  border-radius: 20px;
  box-shadow: 0 25px 10px -15px rgba(0, 0, 0, 0.05);
  position: relative;
  min-height: 60px;
  &.notification-close {
    padding-right: 60px;
  }
  .notification-title {
    margin: 0;
  }
  .notification-close-button {
    position: absolute;
    top: 8px;
    right: 8px;
    margin: 0;
  }
  &.success {
    background-color: $transparentDarkBg;
    color: $white;
  }
  &.error {
    background-color: $transparentRedBg;
    color: $white;
  }
}
#notification-root {
  position: sticky;
  top: 0;
  z-index: 3;
}
