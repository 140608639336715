@import "/src/styles/styles.scss";
.progress {
  margin: 0;
  width: 100%;
  border-radius: 0px;
  height: 15px;

  &::-webkit-progress-bar {
    background-color: $lighGray;
    border-radius: 7px;
  }
  &::-webkit-progress-value {
    background-color: $primaryColor;
    border-radius: 0px;
    -webkit-transition: width 300ms ease;
    -moz-transition: width 300ms ease;
    -o-transition: width 300ms ease;
    transition: width 300ms ease;
  }
  &::-moz-progress-bar {
    /* style rules */
  }
}
