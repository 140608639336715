@import "/src/styles/styles.scss";
.quizComponent {  
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
  .switchTransition {
    grid-row: 2/3;
    min-height: 100%;
  }
  .quizComponent-header {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
  }
}
