@import "/src/styles/styles.scss";
$transition: $animationTransition;
.loginIcon {
  path {
    transition: $transition;
  }
  g {
    transition: $transition;
  }
  #user2, #user3, #lock, #recover {
    opacity: 0;
  }
  #head {
    fill: $white;
  }
  #circle, #key {
    fill: $primaryColor;
  }
  &.loginIconUser {
    #head {
      fill: $white;
    }
  }
  &.loginIconCheck {
    #body {
      d: path(
        "m 50.000216,5 c -10.7238,0.107941 -19.4937,3.455839 -26.3506,8.501223 -6.2778,4.619234 -10.9521,10.661313 -14.0542998,16.94253 -3.3235,6.729479 -4.8425,13.733446 -4.5954,19.556247 0.2124,4.567442 0.7381,9.100249 2.1387,13.686353 1.3833,4.529647 3.6200998,9.111287 7.2512998,13.829647 7.0632,10.146975 23.8154,18.362695 35.6599,17.483895 C 74.50352,94.862305 87.26512,76.62476 88.62192,73.107 c 0.01,-0.170271 2.7932,-4.245443 4.6836,-11.255476 C 94.20222,58.526722 94.89762,54.541715 94.99992,50 95.11002,43.434285 93.29932,36.070481 89.59282,29.229293 85.96142,22.526783 80.51042,16.325917 73.26312,11.8687 64.313599,6.5631776 60.78681,5.25886 50.000216,5 Z"
      );
    }
    #head {
      d: path(
        "m 77.373,27.585 c 0.505103,0.01794 1.625423,0.370916 2.07,0.994 0.475006,0.665732 1.764835,2.188255 -0.232,4.237 C 77.645889,34.421797 55.846534,53.884303 35.012,72.419 23.871775,60.951481 23.680025,60.80731 20.557,57.286 c -2.431451,-2.741539 -1.063094,-4.063438 0.232,-5.236 1.048305,-0.949122 2.696227,-1.450002 4.236,0.233 3.66102,4.001568 5.082572,5.920402 10.452,11.664 10.038833,-9.042853 37.379603,-33.603408 39.73,-35.6 1.013701,-0.861109 1.510487,-0.785277 2.166,-0.762 z"
      );
      fill: $primaryColorL;
    }
  }
  &.loginIconRecover {
    #body {
      d: path(
        "m 43.143,21.435 c -5.754479,0.113516 -8.699,5.015456 -8.699,9.243 0,0 -0.0066,9.212175 0,9.131 -0.996471,0.486623 -4.13495,2.185256 -4.135,6.427 0.0051,0.216767 -0.0098,25.057393 0,25.274 -0.01184,3.7766 2.830543,7.050691 7.054,7.054 5.962182,0.0047 18.85867,0.02512 25.275,0 6.216067,-0.146886 7.134643,-5.529401 7.054,-7.054 -0.01658,-0.313409 -0.04078,-25.194859 0,-25.274 -0.0071,-0.780974 -0.222594,-4.86982 -4.135,-6.427 -3e-5,-3.432624 0.07624,-5.246791 0,-9.131 -0.09573,-4.877444 -3.430568,-9.089632 -8.699,-9.243 -7.217872,-0.05873 -5.032942,-0.02626 -13.715,0 z"
      );
    }
    #head {
      d: path(
        "m 61.449,32.609 c 0.0029,0.505413 0.0029,6.372751 -0.011,6.573 -0.20292,-5.98e-4 -4.644667,0 -6.967,0 -3.137333,0 -9.193904,0.0024 -9.412,0 -0.176297,0.01625 -6.359527,-0.0043 -6.497,0 0.01612,-0.184731 -0.01165,-8.23036 0,-8.504 -0.118305,-1.147919 0.787848,-4.897938 4.581,-4.867 2.34974,0.01917 6.74643,-0.01353 6.882,0 0.173661,-0.0047 3.748211,-0.03172 6.832,0 3.577464,0.0368 4.569672,2.878368 4.581,4.867 z"
      );
      fill: $primaryColor;
    }
    #recover {
      opacity: 1;
    }
  }
  &.loginIconRegister {
    #circle {
      fill: $secondaryColor;
    }
    #user2, #user3 {
      opacity: 1;
    }
  }
  &.loginIconLock {
    #lock {
      opacity: 1;
    }
  }
}
