@import "/src/styles/styles.scss";
$animation-time: 300ms;
.modal-layer {
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: $transparentLightBg;
  overflow: hidden;
  @include min-sm() {
    display: flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
  }
}
.modal-content {
  width: 100%;
  height: 100%;
  background: $white;

  .modal-close {
    margin: 3px;
    position: absolute;
    top: 0;
    right: 0;
  }
  @include min-sm() {
    width: auto;
    height: auto;
    min-width: 300px;
    min-height: 300px;
    max-width: 80%;
    max-height: 80%;
    box-shadow: $shadow;
    border-radius: 15px;
    display: flex;
    align-items: stretch;
    overflow: hidden;
    flex-direction: column;
    .modal-close {
      top: 5px;
      right: 5px;
    }
  }
}
.modal-content-inner {
  overflow: auto;
  padding: 0px;
  height: 100%;
  &.modal-content-close {
    padding: 50px 16px 16px;
  }
  @include min-sm() {
    &.modal-content-close {
      padding: 60px 20px 20px;
    }
  }
}
.revealing {
  opacity: 0;
  transition: opacity #{$animation-time};
  .modal-content {
    transition: all #{$animation-time};
    transform: translateY(100%);
    opacity: 0;
  }
  &.revealing-enter,
  &.revealing-enter-done {
    opacity: 1;
    .modal-content {
      transform: translateY(0);
      opacity: 1;
    }
  }
  &.revealing-exit,
  &.revealing-exit-done {
    opacity: 0;
    .modal-content {
      transform: translateY(100%);
      opacity: 0;
    }
  }
  &.delayExit {
    transition: opacity #{$animation-time} ease 400ms;
    .modal-content {
      transition: all #{$animation-time} ease 400ms;
    }
  }
}
#root {
  transform: scale(1);
  transition: all #{$animation-time};
}
#root.modal-displayed {
  filter: blur(2px);
  transform: scale(0.95);
}
